import React from 'react';
import { Grid } from '@material-ui/core';
import { SearchResult } from '../../../types/api';
import Paper from '../../../elements/Paper';
import { filterMrns } from '../../../utils/AccountDetailsUtil';
import {CardType} from "../../../types/cardTypes";
import withLogger from './HOC/WithLogger';

type LinkedRecordComponentProps = {
    openRemovalModal: any,
    linkedRecord: SearchResult
    verifiedWith: string
}

function LinkedRecordComponent({ linkedRecord, openRemovalModal, verifiedWith}: LinkedRecordComponentProps) {
    return (
        <>
            <Grid
                container
                direction='row'
                style={{ maxWidth: '420px', minWidth: '420px', marginRight: '40px', marginBottom: '20px' }}
            >
                 <Grid item xs>
                    <Paper variant='outlined' cardType={CardType.LinkedCard} onUnlink={openRemovalModal}
                        inputData={linkedRecord} verifiedWith={verifiedWith}
                        mrns={filterMrns([linkedRecord])}></Paper>
                </Grid>
            </Grid>
        </>
    );
}

export default withLogger(LinkedRecordComponent);
