import React, { useContext, useEffect, useState } from 'react';
import SearchPage from './features/search/SearchPage';
import { AuthenticatedTemplate, useMsal, useMsalAuthentication } from '@azure/msal-react';
import { InteractionStatus, InteractionType } from '@azure/msal-browser';
import { ClientConfigContext } from './config/clientConfig';
import { SessionContext } from './session/sessionContext';
import { v4 as uuidv4 } from 'uuid';
import {
    initializeTimerRefreshListeners,
    isUserActive,
    continueSession,
    refreshUserActivityTimestamp,
} from './features/session/sessionTimeout';
import SessionTimeoutModal from './features/session/components/SessionTimeoutModal';
import withLogger from './features/search/components/HOC/WithLogger';

function App() {
    const clientConfiguration = useContext(ClientConfigContext);
    const [showSessionDialog, setSessionDialogVisible] = useState(false);
    const request = {
        scopes: clientConfiguration.b2cTenantConfig.scopes,
    };
    const { login, error } = useMsalAuthentication(InteractionType.Silent, request);
    const { instance, inProgress } = useMsal();

    useEffect(() => {
        if (error && inProgress === InteractionStatus.None) {
            login(InteractionType.Redirect, request);
        }
    }, [error, inProgress]);

    initializeSessionTimeout(clientConfiguration.userActivityLimitSeconds);
    refreshUserActivityTimestamp();

    let idlenessTimer: NodeJS.Timeout;

    function initializeSessionTimeout(userActivityLimitSeconds: number): void {
        if (!idlenessTimer) {
            initializeTimerRefreshListeners();
            idlenessTimer = setInterval(() => {
                if (!isUserActive(userActivityLimitSeconds)) {
                    setSessionDialogVisible(true);
                }
            }, 1000);
        }
    }

    return (
        <AuthenticatedTemplate>
            <SessionContext.Provider value={uuidv4()}>
                <SearchPage />
                <SessionTimeoutModal
                    open={showSessionDialog}
                    onClose={() => {
                        setSessionDialogVisible(false);
                    }}
                    onSelection={(sessionContinue: boolean) => {
                        continueSession(sessionContinue, instance, request.scopes);
                    }}
                />
            </SessionContext.Provider>
        </AuthenticatedTemplate>
    );
}

export default withLogger(App);
