import { Divider, Grid, Typography } from '@material-ui/core';
import axios from 'axios';
import React, { useContext, useState } from 'react';
import Button from '../../../elements/Button';
import { AccountAuthorization, SearchResult } from '../../../types/api';
import getAccessToken from '../../../utils/AccessToken';
import AddLinkedRecordModal from './AddLinkedRecordModal';
import EmailComponent from './EmailComponent';
import EmailUpdateComponent from './EmailUpdateComponent';
import LinkedRecordComponent from './LinkedRecordComponent';
import LinkedRecordRemovalModal from './LinkedRecordRemovalModal';
import TemporaryPasswordComponent from './TemporaryPasswordComponent';
import UpdateEmailModal from './UpdateEmailModal';

import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';
import dayjs from 'dayjs';
import { ClientConfigContext } from '../../../config/clientConfig';
import { SessionContext } from '../../../session/sessionContext';
import { MfaState } from '../../../types/mfaState';
import {
    DATE_TIME_FORMAT,
    DISPLAY_PLACEHOLDER,
    getAuthorizationMethod,
    getDaysAgo,
    sanitizeInput,
} from '../../../utils/AccountDetailsUtil';
import { ApiStatus, changeEmail, getConsumerForEPI, migrateAccount } from '../api';
import withLogger from './HOC/WithLogger';
import ResetMFAModal, { MFAResetResult } from './ResetMFAModal';

type SearchResultDataComponentProps = {
    searchDisplayData: SearchResult[] | undefined;
    searchTerm: string;
    instance: IPublicClientApplication;
    accounts: AccountInfo[];
    searchAccounts: any;
};

type ResetPasswordResponse = {
    result: string;
    newPassword: string;
};

function SearchResultDataComponent({
    searchDisplayData,
    searchTerm,
    instance,
    accounts,
    searchAccounts,
}: SearchResultDataComponentProps) {
    const sessionId = useContext(SessionContext);
    const [displayUpdateEmailModal, setOpenEmailModal] = useState(false);
    const [newEmailSame, setNewEmailSame] = useState(false);
    const [emailChangeAttempted, setEmailChangeAttempted] = useState(false);
    const [emailChangeSuccessful, setEmailChangeSuccessful] = useState(false);
    const [emailChangeMessage, setEmailChangeMessage] = useState('');
    const [emailChangeLoading, setEmailChangeLoading] = useState(false);
    const [passwordChangeAttempted, setPasswordChangeAttempted] = useState(false);
    const [passwordChangeSuccessful, setPasswordChangeSuccessful] = useState(false);
    const [passwordChangeMessage, setPasswordChangeMessage] = useState('');
    const [passwordChangeLoading, setPasswordChangeLoading] = useState(false);
    const [openAddLinkedRecordModal, setOpenAddLinkedRecordModal] = useState(false);
    const [openLinkedRecordRemovalModal, setOpenLinkedRecordRemovalModal] = useState(false);
    const [isMfaResetModalOpen, setIsMfaResetModalOpen] = useState(false);
    const [removalName, setRemovalName] = useState('');
    const [removalMyChartUsername, setRemovalMyChartUsername] = useState('');
    const [removalIdentifier, setRemovalIdentifier] = useState('');
    const [removalSystem, setRemovalSystem] = useState('');
    const [searchResults, setSearchResult] = useState(searchDisplayData);
    //This will change for userName only when we will try to link after calling update sign-in name
    const [searchTermModified, setSearchTermModified] = useState(searchTerm);

    const clientConfiguration = useContext(ClientConfigContext);
    const handleOpenUpdateSignInNameModal = () => {
        setOpenEmailModal(true);
    };

    const handleClose = () => {
        setOpenEmailModal(false);
    };

    const handleOpenAddLinkedRecordModal = () => {
        setOpenAddLinkedRecordModal(true);
    };

    const handleCloseAddLinkedRecordModal = () => {
        setOpenAddLinkedRecordModal(false);
    };
    const handleCreateAccount = async () => {
        setPasswordChangeLoading(true);
        setPasswordChangeAttempted(false);
        let errorFlag = false;
        let errorMessage = 'Account could not be created at this time.Please contact Support for the SSO platform.';
        let responseValue = '';

        const { result, newPassword } = await migrateAccount(
            searchResults,
            instance,
            accounts,
            clientConfiguration,
            sessionId,
        );

        responseValue = result;
        if (responseValue === 'conflict') {
            //When there is already an azure account same as the mychart username  and if we try to migrate account again, display inline message
            errorFlag = true;
            errorMessage =
                'There is already an SSO account with the same MyChart username. Before linking, please confirm that the SSO account and MyChart record belong to the same user. If not, please contact Support for the SSO platform.';
        } else if (responseValue === 'EmailNotFoundOnFile') {
            //When the EPIC Patient file does not have email, SSo account can not be created.
            errorFlag = true;
            errorMessage =
                'The patient record does not have a valid email address on file. To create the account, you must update the patient record in Hyperspace with a valid email address.';
        } else if (responseValue) {
            const epi = searchResults?.[0]?.epi;
            if (epi) {
                const result = await getConsumerForEPI(
                    epi,
                    responseValue,
                    instance,
                    accounts,
                    clientConfiguration,
                    sessionId,
                );
                setPasswordChangeLoading(false);
                setPasswordChangeAttempted(true);
                const azureId = result?.externalIdentifiers.filter((f) => f.idType == 'azureB2C')[0]?.idValue ?? '';

                const searchData: SearchResult[] = [...searchResults];

                if (result) {
                    const date = result?.profile?.accountCreationDate;
                    const creationDate =
                        date && dayjs(date).isValid()
                            ? new Date(Date.parse(date)).toLocaleString('en-US', DATE_TIME_FORMAT)
                            : DISPLAY_PLACEHOLDER;
                    searchData[0].accountCreationDate = creationDate;
                    searchData[0].requireMigrateAccount = false;
                    searchData[0].userProvidenceAccountUsername = searchData[0].mychartUserName;
                    searchData[0].isEmailVerified = true;

                    searchData[0].daysSinceAccCreation =
                        date && dayjs(date).isValid() ? getDaysAgo(date).toString() + ' days ago' : DISPLAY_PLACEHOLDER;
                    searchData[0].authorizationDate = creationDate;
                    searchData[0].daysSinceAuth =
                        date && dayjs(date).isValid() ? getDaysAgo(date).toString() + ' days ago' : DISPLAY_PLACEHOLDER;
                    searchData[0].accountAuthorizations = result?.accountAuthorizations;
                    searchData[0].azureId = azureId;
                    searchData[0].externalIdentifiers = result?.externalIdentifiers;
                    searchData[0].healthSystemId = responseValue;
                    searchData[0].isAuthorized = true;
                } else {
                    const accAuthData = {
                        authorizationDate: '',
                        authorizationMethod: 'Lazy Migration',
                        authorizationSystem: searchResults[0]?.system,
                        healthSystemId: responseValue,
                        authorizationDetails: {},
                    };
                    searchData[0].accountCreationDate = DISPLAY_PLACEHOLDER;
                    searchData[0].requireMigrateAccount = false;
                    searchData[0].userProvidenceAccountUsername = searchData[0].mychartUserName;
                    searchData[0].isEmailVerified = true;
                    searchData[0].authorizationDate = '';
                    searchData[0].daysSinceAuth = '';
                    searchData[0].accountAuthorizations = [accAuthData as AccountAuthorization];
                    searchData[0].isAuthorized = false;
                }
                setSearchResult(searchData);
                setPasswordChangeSuccessful(true);
                setPasswordChangeMessage(newPassword);
            } else {
                errorFlag = true;
            }
        } else {
            errorFlag = true;
        }
        if (errorFlag) {
            setPasswordChangeLoading(false);
            setPasswordChangeAttempted(true);
            setPasswordChangeSuccessful(false);
            setPasswordChangeMessage(errorMessage);
        }
    };

    const handleOpenLinkedRecordRemovalModal = (
        name: string,
        myChartUsername: string,
        identifier: string,
        system: string,
    ) => {
        setOpenLinkedRecordRemovalModal(true);
        setRemovalName(name);
        setRemovalMyChartUsername(myChartUsername);
        setRemovalIdentifier(identifier);
        setRemovalSystem(system);
    };

    const handleCloseLinkedRecordRemovalModal = () => {
        setOpenLinkedRecordRemovalModal(false);
    };

    const submitEmailChange = async (newEmail: string, verificationCode: string): Promise<boolean | Error> => {
        if (searchResults?.length) {
            setEmailChangeLoading(true);
            setEmailChangeAttempted(false);
            const userId = searchResults[0].azureId;
            const hsId = searchResults[0].healthSystemId;
            const res = await changeEmail(
                newEmail,
                userId,
                hsId,
                instance,
                accounts,
                clientConfiguration,
                verificationCode,
            );
            setEmailChangeLoading(false);

            if (res.status === ApiStatus.Success) {
                if (searchResults && newEmail === searchResults[0]?.userProvidenceAccountUsername) {
                    setNewEmailSame(true);
                } else {
                    const newSearchResults = searchResults;
                    if (newSearchResults) {
                        newSearchResults[0].isEmailVerified = true;
                        newSearchResults[0].userProvidenceAccountUsername = newEmail;
                    }
                    setSearchResult(newSearchResults);
                    setNewEmailSame(false);
                }
                setEmailChangeAttempted(true);
                setEmailChangeSuccessful(true);
                setEmailChangeMessage(newEmail);
                if (searchResults?.[0]?.inputSearchType.toLowerCase() === 'username') {
                    setSearchTermModified(newEmail);
                }
            } else {
                setEmailChangeAttempted(true);
                setEmailChangeSuccessful(false);
                setNewEmailSame(false);
                const error = res.error as Error;
                if (error.message.includes('conflicting object')) {
                    setEmailChangeMessage('The email is already taken.');
                } else if (error.message.includes('internal')) {
                    setEmailChangeMessage('Internal server error.');
                } else {
                    setEmailChangeMessage(error.message);
                }
                return error;
            }
        }
        return true;
    };

    // TODO: Move out of view
    const sendVerificationCodeToEmail = async (newEmail: string, language = 'en'): Promise<boolean | string> => {
        try {
            const jwt = await getAccessToken(instance, accounts, clientConfiguration.b2cTenantConfig.scopes);
            await axios.post(
                `${clientConfiguration.pimsApiUrl}/v1/consumer/sendVerificationCode`,
                {
                    mode: 'email',
                    value: newEmail,
                    brand: 'affiliates', // Using affiliates "MyChart" branding to cover CT users
                    language,
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + jwt,
                    },
                },
            );
            return true;
        } catch (err: any) {
            return err.response ? err.response.data.message : err;
        }
    };

    const submitPasswordChange = async () => {
        setPasswordChangeLoading(true);
        setPasswordChangeAttempted(false);
        const { result, newPassword } = await changePassword();
        setPasswordChangeLoading(false);
        setPasswordChangeAttempted(true);
        if (newPassword.length > 0) {
            setPasswordChangeSuccessful(true);
            setPasswordChangeMessage(newPassword);
        } else {
            setPasswordChangeSuccessful(false);
            setPasswordChangeMessage(result);
        }
    };

    // TODO: Why is API requests snuck with the view/UI code?
    const changePassword = async (): Promise<ResetPasswordResponse> => {
        if (searchResults?.length) {
            try {
                const jwt = await getAccessToken(instance, accounts, clientConfiguration.b2cTenantConfig.scopes);
                const response = await axios.post(
                    '/api/resetPassword',
                    {
                        azureUserId: searchResults[0].azureId,
                        healthSystemId: searchResults[0].healthSystemId,
                    },
                    {
                        headers: {
                            Authorization: 'Bearer ' + jwt,
                        },
                    },
                );
                return { result: 'success', newPassword: response.data.newPassword };
            } catch (err: any) {
                console.error(err);
                return { result: err.response.data.message, newPassword: '' };
            }
        } else {
            return { result: 'No password change attempted.', newPassword: '' };
        }
    };

    const handleResetMFAClick = () => {
        setIsMfaResetModalOpen(true);
    };

    // After a successful MFA reset, update the selectedAccount and close the modal
    const handleResetMFA = (resetResult: MFAResetResult): void => {
        setSearchResult(
            searchResults?.map((searchResult) => ({
                ...searchResult,
                ...resetResult,
            })),
        );
        handleCloseMFAModal();
    };

    // Close the modal
    const handleCloseMFAModal = () => {
        setIsMfaResetModalOpen(false);
    };

    const renderEmailChangeLoading = () => {
        return (
            <Grid container direction='row' xs={12}>
                <Grid item xs className='mt10'>
                    <Typography variant='h4' style={{ color: '#084897' }}>
                        Email update loading...
                    </Typography>
                </Grid>
            </Grid>
        );
    };

    const renderEmailChange = () => {
        return (
            <EmailUpdateComponent
                show={emailChangeAttempted}
                setShow={setEmailChangeAttempted}
                message={emailChangeMessage}
                successful={emailChangeSuccessful}
                newEmailSame={newEmailSame}
            />
        );
    };

    const renderPasswordChangeLoading = () => {
        return (
            <Grid container direction='row' xs={12}>
                <Grid item xs className='mt15'>
                    <Typography variant='h4' style={{ color: '#084897' }}>
                        {'Password update loading...'}
                    </Typography>
                </Grid>
            </Grid>
        );
    };

    const renderPasswordChange = () => {
        return (
            <TemporaryPasswordComponent
                show={passwordChangeAttempted}
                setShow={setPasswordChangeAttempted}
                message={passwordChangeMessage}
                successful={passwordChangeSuccessful}
            />
        );
    };

    const renderLinkedRecords = () => {
        if (searchResults) {
            return searchResults.map((result, index) => {
                if (!(result.requireCreateAccount && result.requireMigrateAccount) && (result.mrn || result.system)) {
                    return (
                        <LinkedRecordComponent
                            key={'Cards' + index}
                            linkedRecord={result}
                            verifiedWith={getAuthorizationMethod(result)}
                            openRemovalModal={handleOpenLinkedRecordRemovalModal}
                        />
                    );
                }
            });
        }
    };

    const renderNotifications = () => {
        if (searchResults) {
            if (emailChangeAttempted && !passwordChangeAttempted && !emailChangeLoading && !passwordChangeLoading) {
                // TFFF
                return <>{renderEmailChange()}</>;
            } else if (
                !emailChangeAttempted &&
                passwordChangeAttempted &&
                !emailChangeLoading &&
                !passwordChangeLoading
            ) {
                // FTFF
                return <>{renderPasswordChange()}</>;
            } else if (
                !emailChangeAttempted &&
                !passwordChangeAttempted &&
                emailChangeLoading &&
                !passwordChangeLoading
            ) {
                // FFTF
                return <>{renderEmailChangeLoading()}</>;
            } else if (
                !emailChangeAttempted &&
                !passwordChangeAttempted &&
                !emailChangeLoading &&
                passwordChangeLoading
            ) {
                // FFFT
                return <>{renderPasswordChangeLoading()}</>;
            } else if (
                emailChangeAttempted &&
                passwordChangeAttempted &&
                !emailChangeLoading &&
                !passwordChangeLoading
            ) {
                // TTFF
                return (
                    <>
                        {renderEmailChange()}
                        {renderPasswordChange()}
                    </>
                );
            } else if (
                emailChangeAttempted &&
                !passwordChangeAttempted &&
                !emailChangeLoading &&
                passwordChangeLoading
            ) {
                // TFFT
                return (
                    <>
                        {renderEmailChange()}
                        {renderPasswordChangeLoading()}
                    </>
                );
            } else if (
                !emailChangeAttempted &&
                passwordChangeAttempted &&
                emailChangeLoading &&
                !passwordChangeLoading
            ) {
                // FTTF
                return (
                    <>
                        {renderEmailChangeLoading()}
                        {renderPasswordChange()}
                    </>
                );
            } else if (
                !emailChangeAttempted &&
                !passwordChangeAttempted &&
                emailChangeLoading &&
                passwordChangeLoading
            ) {
                // FFTT
                return (
                    <>
                        {renderEmailChangeLoading()}
                        {renderPasswordChangeLoading()}
                    </>
                );
            }
        }
    };

    const getLinkRecordsHeader = () => {
        if (searchResults && !searchResults[0]?.requireMigrateAccount) {
            return (
                <>
                    <Grid container direction='column' className='mt40'>
                        <Grid item xs={2}>
                            <Typography variant='h5'>Linked Records</Typography>
                        </Grid>
                        <Grid item xs={2} className='mt20 mb10 ml20'>
                            <Button
                                type='button'
                                variant='contained'
                                color='secondary'
                                fontSize='12px'
                                onClick={handleOpenAddLinkedRecordModal}
                            >
                                LINK ACCOUNT
                            </Button>
                        </Grid>
                    </Grid>
                </>
            );
        }
    };

    const getSSOAccountActionButtons = () => {
        if (searchResults) {
            if (searchResults[0]?.requireMigrateAccount) {
                if (searchResults[0]?.requireCreateAccount) {
                    return <></>;
                } else {
                    return (
                        <>
                            <Grid container direction='row' className='createAccountCTA'>
                                <Grid item xs={4}>
                                    <Button
                                        type='button'
                                        disabled={passwordChangeLoading}
                                        variant='contained'
                                        color='secondary'
                                        fontSize='12px'
                                        onClick={handleCreateAccount}
                                    >
                                        Create Account
                                    </Button>
                                </Grid>
                            </Grid>
                        </>
                    );
                }
            } else {
                return (
                    <>
                        <Grid container direction='row' className='mlt20'>
                            <Grid item xs={2} className='mw200'>
                                <Button
                                    type='button'
                                    variant='contained'
                                    color='secondary'
                                    fontSize='12px'
                                    onClick={handleOpenUpdateSignInNameModal}
                                >
                                    UPDATE SIGN-IN NAME
                                </Button>
                            </Grid>
                            <Grid item xs={2}>
                                <Button
                                    id='resetPasswordButton'
                                    disabled={passwordChangeLoading}
                                    type='button'
                                    variant='contained'
                                    color='secondary'
                                    onClick={submitPasswordChange}
                                    fontSize='12px'
                                >
                                    RESET PASSWORD
                                </Button>
                            </Grid>
                            <Grid container item xs={4} alignItems='flex-start'>
                                <Button
                                    type='button'
                                    disabled={searchResults?.[0]?.mfaState === MfaState.NotConfigured}
                                    variant='contained'
                                    color='secondary'
                                    onClick={async () => {
                                        handleResetMFAClick();
                                    }}
                                    fontSize='12px'
                                >
                                    RESET
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                );
            }
        }
    };

    const message = () => {
        return searchResults?.length == 1 && searchResults[0]?.requireMigrateAccount ? (
            searchResults[0]?.requireCreateAccount ? (
                <>
                    <Typography variant='h3'>
                        This{' '}
                        {searchResults[0]?.inputSearchType?.toLowerCase() === 'username'
                            ? searchResults[0]?.inputSearchType
                            : searchResults[0]?.inputSearchType?.toUpperCase()}{' '}
                        -<span className='searchMessage'>{` ${searchTermModified} `}</span>exists in EPIC but does not
                        have a MyChart account.
                    </Typography>
                </>
            ) : (
                <>
                    <Typography variant='h3'>
                        An SSO account for{' '}
                        {searchResults[0]?.inputSearchType?.toLowerCase() === 'username'
                            ? searchResults[0]?.inputSearchType
                            : searchResults[0]?.inputSearchType?.toUpperCase()}{' '}
                        -<span className='searchMessage'>{` ${searchTermModified} `}</span>does not exist.
                    </Typography>
                </>
            )
        ) : (
            <>
                <Typography variant='h3'>
                    Providence account details for{' '}
                    {searchResults && searchResults[0]?.inputSearchType?.toLowerCase() === 'username'
                        ? searchResults[0]?.inputSearchType
                        : searchResults?.[0]?.inputSearchType?.toUpperCase()}{' '}
                    -<span className='searchMessage'>{` ${searchTermModified} `}</span>
                </Typography>
            </>
        );
    };

    return (
        <>
            {renderNotifications()}
            <Grid item className='mt30'>
                {message()}
            </Grid>
            <Grid container direction='column' className='mt20'>
                {searchResults && searchResults[0]?.requireMigrateAccount ? (
                    searchResults[0]?.requireCreateAccount ? (
                        <Typography variant='h5' className='mt10'>
                            <span>1. Please help the patient create a MyChart account through Hyperspace. </span> <br />
                            <span className='mt5'>
                                2. Once complete, search again and assist the patient with creating the SSO account
                                here.
                            </span>
                        </Typography>
                    ) : (
                        <Typography variant='h5' className='mb30'>
                            Please create an account for this{' '}
                            {searchResults && searchResults[0]?.inputSearchType?.toLowerCase() === 'username'
                                ? searchResults[0]?.inputSearchType
                                : searchResults[0]?.inputSearchType?.toUpperCase()}
                        </Typography>
                    )
                ) : (
                    <EmailComponent
                        isEmailVerified={searchResults ? searchResults[0]?.isEmailVerified : false}
                        isNewEmail={emailChangeAttempted}
                        userProvidenceAccountUsername={sanitizeInput(searchResults?.[0]?.userProvidenceAccountUsername)}
                        userAccountCreationDate={searchResults?.[0]?.accountCreationDate}
                        userLastSignedInDate={searchResults?.[0]?.userLastSignedInDate}
                        daysSinceSignin={searchResults?.[0]?.daysSinceLogin}
                        daysSinceAccCreation={searchResults?.[0]?.daysSinceAccCreation}
                        mfaVerificationMethod={searchResults?.[0]?.mfaVerificationMethod}
                        daysSinceMFAEnabled={searchResults?.[0]?.daysSinceMFAEnabled}
                        mfaEnabledDate={searchResults?.[0]?.mfaEnabledDate}
                        mfaState={searchResults?.[0]?.mfaState}
                    />
                )}

                {getSSOAccountActionButtons()}
            </Grid>
            <Divider />
            <Divider />
            {searchResults && (
                <UpdateEmailModal
                    userProvidenceAccountUsername={searchResults[0]?.userProvidenceAccountUsername}
                    open={displayUpdateEmailModal}
                    handleClose={handleClose}
                    changeEmail={submitEmailChange}
                    sendCode={sendVerificationCodeToEmail}
                />
            )}
            {searchResults && (
                <AddLinkedRecordModal
                    open={openAddLinkedRecordModal}
                    handleClose={handleCloseAddLinkedRecordModal}
                    healthSystemId={searchResults[0]?.healthSystemId}
                    region={searchResults[0]?.system}
                    searchMethod={searchAccounts}
                    searchTerm={searchTermModified}
                />
            )}
            {searchResults && (
                <LinkedRecordRemovalModal
                    open={openLinkedRecordRemovalModal}
                    handleClose={handleCloseLinkedRecordRemovalModal}
                    healthSystemId={searchResults[0]?.healthSystemId}
                    region={searchResults[0]?.system}
                    searchMethod={searchAccounts}
                    searchTerm={searchTermModified}
                    mrn={removalIdentifier}
                    myChartUsername={removalMyChartUsername}
                    name={removalName}
                    system={removalSystem}
                />
            )}
            {searchResults && (
                <ResetMFAModal
                    open={isMfaResetModalOpen}
                    onReset={handleResetMFA}
                    onClose={handleCloseMFAModal}
                    userIdValue={searchResults?.[0]?.azureId}
                />
            )}
            {getLinkRecordsHeader()}
            <Grid container direction='row' className='mt10 ml20'>
                {renderLinkedRecords()}
            </Grid>
        </>
    );
}

export default withLogger(SearchResultDataComponent);
