/**
 * TODO: Refactor these calls, fix types, and add tests.
 */
import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { AuthorizeInputType, ConsumerIdentity, MrnData, SearchResponse, SearchResult } from '../../types/api';
import { ClientConfig } from '../../types/serverConfig';
import getAccessToken from '../../utils/AccessToken';

export enum ApiStatus {
    Success,
    Error,
}

type ApiSuccess<ResponseDataType> = {
    status: ApiStatus.Success;
    data: ResponseDataType;
};

type ApiError = {
    status: ApiStatus.Error;
    error?: Error;
};

type ApiResult<ResponseDataType> = Promise<ApiSuccess<ResponseDataType> | ApiError>;

type MigrateAccountResponse = { result: string; newPassword: string };

export const auditPageView = async (instance: any, accounts: any, clientConfiguration: any, sessionId: string) => {
    const jwt = await getAccessToken(instance, accounts, clientConfiguration.b2cTenantConfig.scopes);
    const correlationId = uuidv4();
    console.log(`Correlation ID: ${correlationId}`);
    console.log(`Session ID: ${sessionId}`);
    axios.post(
        '/api/audit',
        {},
        {
            headers: {
                Authorization: 'Bearer ' + jwt,
                'correlation-id': correlationId,
                'session-id': sessionId,
            },
        },
    );
};

function is2xxResponse(status: number): boolean {
    return status >= 200 && status < 300;
}

export const getMrnData = async (
    mrn: string,
    instance: any,
    accounts: any,
    clientConfiguration: any,
    sessionId: string,
): ApiResult<MrnData> => {
    const jwt = await getAccessToken(instance, accounts, clientConfiguration.b2cTenantConfig.scopes);
    const correlationId = uuidv4();
    console.log(`Correlation ID: ${correlationId}`);
    console.log(`Session ID: ${sessionId}`);
    try {
        const res = await axios.get<MrnData>('/api/mrnData', {
            headers: {
                Authorization: 'Bearer ' + jwt,
                mrn: mrn,
                'correlation-id': correlationId,
                'session-id': sessionId,
            },
        });
        if (is2xxResponse(res.status)) {
            return { status: ApiStatus.Success, data: res.data };
        } else {
            console.log('could not find mrn. Status: ' + res.status + ', response: ' + res.statusText);
            return { status: ApiStatus.Error };
        }
    } catch (error: any) {
        console.log("network request error with 'mrnData'. " + error.message);
        return { status: ApiStatus.Error };
    }
};

export const linkRecord = async (
    mrn: string,
    myChartUsername: string,
    healthSystemId: string,
    instance: any,
    accounts: any,
    clientConfiguration: any,
    sessionId: string,
): ApiResult<void> => {
    const jwt = await getAccessToken(instance, accounts, clientConfiguration.b2cTenantConfig.scopes);
    const correlationId = uuidv4();
    console.log(`Correlation ID: ${correlationId}`);
    console.log(`Session ID: ${sessionId}`);
    try {
        const res = await axios.post(
            '/api/linkRecord',
            {
                mrn,
                myChartUsername,
                healthSystemId,
            },
            {
                headers: {
                    Authorization: 'Bearer ' + jwt,
                    'correlation-id': correlationId,
                    'session-id': sessionId,
                },
            },
        );

        if (is2xxResponse(res.status)) {
            return { status: ApiStatus.Success, data: undefined };
        } else {
            console.log('could not link record. Status: ' + res.status + ', response: ' + res.statusText);
            return { status: ApiStatus.Error };
        }
    } catch (error: any) {
        console.log("network request error with 'linkRecord'. " + error.message);
        return { status: ApiStatus.Error };
    }
};

export const deleteRecord = async (
    mrn: string,
    myChartUsername: string,
    healthSystemId: string,
    instance: any,
    accounts: any,
    clientConfiguration: any,
    sessionId: string,
) => {
    const jwt = await getAccessToken(instance, accounts, clientConfiguration.b2cTenantConfig.scopes);
    const correlationId = uuidv4();
    console.log(`Correlation ID: ${correlationId}`);
    console.log(`Session ID: ${sessionId}`);
    try {
        const res = await axios.post(
            '/api/deleteRecord',
            {
                mrn: mrn,
                myChartUsername: myChartUsername,
                healthSystemId: healthSystemId,
            },
            {
                headers: {
                    Authorization: 'Bearer ' + jwt,
                    'correlation-id': correlationId,
                    'session-id': sessionId,
                },
            },
        );

        if (is2xxResponse(res.status)) {
            return { status: ApiStatus.Success, data: undefined };
        } else {
            console.log('could not delete record. Status: ' + res.status + ', response: ' + res.statusText);
            return { status: ApiStatus.Error };
        }
    } catch (error: any) {
        console.log("network request error with 'deleteRecord'. " + error.message);
        return { status: ApiStatus.Error };
    }
};

export const searchAccounts = async (
    accountId: string,
    region: string,
    instance: any,
    accounts: any,
    clientConfiguration: any,
    sessionId: string,
): Promise<SearchResponse> => {
    const jwt = await getAccessToken(instance, accounts, clientConfiguration.b2cTenantConfig.scopes);
    const correlationId = uuidv4();
    console.log(`Correlation ID: ${correlationId}`);
    console.log(`Session ID: ${sessionId}`);
    const res = await axios.get<SearchResponse>('/api/search', {
        headers: {
            Authorization: 'Bearer ' + jwt,
            accountId: accountId,
            region: region,
            'correlation-id': correlationId,
            'session-id': sessionId,
        },
    });

    return res.data;
};

export const migrateAccount = async (
    searchResults: SearchResult[] | undefined,
    instance: any,
    accounts: any,
    clientConfiguration: any,
    sessionId: string,
): Promise<MigrateAccountResponse> => {
    try {
        const jwt = await getAccessToken(instance, accounts, clientConfiguration.b2cTenantConfig.scopes);
        const correlationId = uuidv4();
        console.log(`Correlation ID: ${correlationId}`);
        console.log(`Session ID: ${sessionId}`);
        const response = await axios.post(
            '/api/account',
            {
                myChartUsername: searchResults ? searchResults[0]?.mychartUserName : '',
                signUpApplication: 'PEC Admin Portal',
                myChartSystem: searchResults ? searchResults[0]?.system : '',
            },
            {
                headers: {
                    Authorization: 'Bearer ' + jwt,
                    'correlation-id': correlationId,
                    'session-id': sessionId,
                },
            },
        );

        return { result: response.data?.result.healthSystemId, newPassword: response.data?.newPassword };
    } catch (error: any) {
        let retValue = '';
        if (error.response?.data?.name === 'EmailNotFoundOnFile') {
            // Handle Email not available on patient file
            retValue = 'EmailNotFoundOnFile';
        } else if (error.response?.data?.status == 409) {
            //Handle duplicate account issue while migrating account
            retValue = 'conflict';
        }
        return { result: retValue, newPassword: '' };
    }
};

export const getConsumerForEPI = async (
    useridvalue: string,
    healthSystemId: string,
    instance: any,
    accounts: any,
    clientConfiguration: any,
    sessionId: string,
): Promise<ConsumerIdentity | null> => {
    try {
        const jwt = await getAccessToken(instance, accounts, clientConfiguration.b2cTenantConfig.scopes);
        const correlationId = uuidv4();
        console.log(`Correlation ID: ${correlationId}`);
        console.log(`Session ID: ${sessionId}`);
        const response = await axios.get(
            `${clientConfiguration.pimsApiUrl}/v1/consumer/getConsumerForAValidIdentifier?useridtype=EPI`,
            {
                headers: {
                    Authorization: 'Bearer ' + jwt,
                    'correlation-id': correlationId,
                    'session-id': sessionId,
                    useridvalue: useridvalue,
                },
            },
        );
        if (response.data) {
            const filteredData = response.data.filter((f: any) => f.healthSystemId == healthSystemId);
            return filteredData && filteredData.length > 0 ? filteredData[0] : null;
        } else {
            return null;
        }
    } catch (err: any) {
        return null;
    }
};

export const changeEmail = async (
    newEmail: string,
    userId: string,
    healthSystemId: string,
    instance: IPublicClientApplication,
    accounts: AccountInfo[],
    clientConfiguration: ClientConfig,
    verificationCode: string,
): ApiResult<void> => {
    try {
        const jwt = await getAccessToken(instance, accounts, clientConfiguration.b2cTenantConfig.scopes);
        const correlationId = uuidv4();
        await axios.post(
            '/api/changeEmail',
            {
                userId: userId,
                newEmail: newEmail,
                verificationCode,
                healthSystemId,
            },
            {
                headers: {
                    Authorization: 'Bearer ' + jwt,
                    'correlation-id': correlationId,
                },
            },
        );
        return {
            status: ApiStatus.Success,
            data: undefined,
        };
    } catch (err: any) {
        return {
            status: ApiStatus.Error,
            error: err.response?.data,
        };
    }
};

export const authorizeByPECUser = async (
    data: AuthorizeInputType,
    domain: string,
    instance: any,
    accounts: any,
    clientConfiguration: any,
    sessionId: string,
): Promise<boolean> => {
    try {
        const jwt = await getAccessToken(instance, accounts, clientConfiguration.b2cTenantConfig.scopes);
        const correlationId = uuidv4();
        console.log(`Correlation ID: ${correlationId}`);
        console.log(`Session ID: ${sessionId}`);
        await axios.post(`/api/authorizeUser`, data, {
            headers: {
                Authorization: 'Bearer ' + jwt,
                'correlation-id': correlationId,
                'session-id': sessionId,
                'x-spi-domain': domain,
            },
        });
        return true;
    } catch (error: any) {
        console.log('network request error with authorizeByPECUser ' + error.message);
        return false;
    }
};

export const resetMFAInfo = async (
    useridvalue: string,
    instance: any,
    accounts: any,
    clientConfiguration: any,
    sessionId: string,
): Promise<ApiStatus> => {
    try {
        const jwt = await getAccessToken(instance, accounts, clientConfiguration.b2cTenantConfig.scopes);
        const correlationId = uuidv4();
        console.log(`Correlation ID: ${correlationId}`);
        console.log(`Session ID: ${sessionId}`);
        const data = {
            userId: useridvalue,
        };
        await axios.post(`/api/reset`, data, {
            headers: {
                Authorization: 'Bearer ' + jwt,
                'correlation-id': correlationId,
                'session-id': sessionId,
            },
        });
        return ApiStatus.Success;
    } catch (error: any) {
        return ApiStatus.Error;
    }
};
