/**
 * Session timeout for PAP is implemented based on 
 * https://praiahealth.atlassian.net/wiki/spaces/SPIA/pages/35305077/PIP+-+Client-Side+Logout
 * 
 */
import { IPublicClientApplication } from "@azure/msal-browser";

export const PAP_USER_ACTIVITY_KEY = "pap_user_activity";

/**
 * Sets the user activity timestamp to the current time.
 */
export function refreshUserActivityTimestamp(): void {
    const currentTime = new Date().getTime();
    sessionStorage.setItem(PAP_USER_ACTIVITY_KEY, `${currentTime}`);
}

/**
 * User is considered active if the time duration from the last user activity timestamp is less than the allowed duration (in seconds).
 * If the timestamp does not exist, then it should be considered expired.
 * @param userActivityLimitSeconds the limit that the user gets to idle on PAP
 */
export function isUserActive(userActivityLimitSeconds = 3600): boolean {
    const lastUserTimestamp = sessionStorage.getItem(PAP_USER_ACTIVITY_KEY) || "0";
    const timeDiff = new Date().getTime() - Number.parseInt(lastUserTimestamp);
    return Math.floor(timeDiff / 1000) < userActivityLimitSeconds;
}

/**
 * Adds event listeners to the browser window. Refreshes the user-activity on any clicks and key inputs.
 */
export function initializeTimerRefreshListeners(): void {
    const events = ["click", "keyup"];
    events.forEach((event) => {
        window.addEventListener(event, refreshUserActivityTimestamp);
    });
}


/**
 * Handles the user input(Logout Now/ Continue session) from session time out dialog
 * @param continueSession user selection from session time out
 */
export function continueSession(sessionContinue: boolean, instance: IPublicClientApplication, scopes: string[]): void {
    if (sessionContinue) { 
        instance.ssoSilent({ scopes: scopes }) // extends the session every time user clicks continue session
        .catch(() => {
            instance.loginRedirect({scopes: scopes, prompt: 'none'});
        });
    } else {
        instance.logout({ account: instance.getAllAccounts()[0]}); //hard logout
    }
}
