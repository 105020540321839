import React, { useContext, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Modal, Typography, Grid, TextField, Select, MenuItem, InputLabel } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import validator from 'validator';
import { removeMigratedAccountPrefix } from '../../../utils/Prefix';
import { ClientConfigContext } from '../../../config/clientConfig';
import Button from '../../../elements/Button';
import withLogger from './HOC/WithLogger';

type UpdateEmailModalProps = {
    userProvidenceAccountUsername: string;
    open: boolean;
    handleClose: () => void;
    changeEmail: (email: string, verificationCode: string) => Promise<boolean | Error>;
    sendCode: (email: string, lang: string) => Promise<boolean | string>;
};

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            position: 'absolute',
            width: 448,
            height: 410,
            backgroundColor: theme.palette.background.paper,
            borderRadius: '24px',
            boxShadow: theme.shadows[5],
        },
    }),
);

const validateEmail = (data: string) => validator.isEmail(data);

const validateVerificationCode = (code: string) =>
    validator.isNumeric(code) && validator.isLength(code, { min: 6, max: 6 });

function UpdateEmailModal({
    userProvidenceAccountUsername,
    open,
    handleClose,
    changeEmail,
    sendCode,
}: UpdateEmailModalProps) {
    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const clientConfiguration = useContext(ClientConfigContext);
    const [modalStyle] = useState(getModalStyle);
    const [isCodeSent, setIsCodeSent] = useState(false);
    const [newEmail, setNewEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const { handleSubmit, errors, control, formState } = useForm({ mode: 'onChange' });
    const { isDirty, isValid } = formState;

    const closeModal = () => {
        setIsCodeSent(false);
        setNewEmail('');
        setErrorMessage('');
        handleClose();
    };

    const onSubmit = async (data: any) => {
        if (!isCodeSent) {
            const result = await sendCode(data.newEmail, data.emailLanguage);
            if (result === true) {
                setIsCodeSent(true);
                setNewEmail(data.newEmail);
                setErrorMessage('');
            } else {
                setErrorMessage('Failed to send verification code email: ' + result);
            }
        } else {
            const result = await changeEmail(newEmail, data.verificationCode);
            if (result === true) {
                closeModal();
            } else {
                const resultError = result as Error;
                if (resultError.message === 'Wrong code provided.') {
                    setErrorMessage('Verification code failed');
                } else {
                    closeModal();
                }
            }
        }
    };

    const sendCodeForm = (
        <>
            <Grid item xs={12} style={{ marginLeft: '20px', marginBottom: '20px' }}>
                <Typography variant='h6'>
                    {removeMigratedAccountPrefix(userProvidenceAccountUsername, clientConfiguration)}
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
                style={{ marginLeft: '20px', marginRight: '20px', marginBottom: errorMessage ? '0px' : '21px' }}
            >
                <InputLabel id='newEmailLabel'>New email</InputLabel>
                <Controller
                    as={TextField}
                    control={control}
                    variant='outlined'
                    defaultValue=''
                    name='newEmail'
                    rules={{
                        required: true,
                        validate: validateEmail,
                    }}
                    id='newEmail'
                    fullWidth
                    error={!!errorMessage || errors.account?.type === 'required'}
                    helperText={
                        errorMessage ? errorMessage : errors.account?.type === 'required' && 'New email required'
                    }
                    placeholder='example@domain_name.com'
                    InputLabelProps={{
                        shrink: true,
                        error: false,
                    }}
                />
            </Grid>
            <Grid item xs={12} style={{ marginLeft: '20px', marginRight: '20px', marginBottom: '64px' }}>
                <InputLabel id='emailLanguageLabel'>Preferred Language</InputLabel>
                <Controller
                    as={
                        <Select fullWidth labelId='emailLanguageLabel'>
                            <MenuItem value='en'>English</MenuItem>
                            <MenuItem value='es'>Spanish</MenuItem>
                        </Select>
                    }
                    control={control}
                    variant='outlined'
                    defaultValue='en'
                    name='emailLanguage'
                    id='emailLanguage'
                />
            </Grid>
            <Grid item xs={5} style={{ marginLeft: '20px', marginRight: '28px' }}>
                <Button
                    type='button'
                    variant='contained'
                    color='secondary'
                    fontSize='14px'
                    onClick={closeModal}
                    heightOverride='28px'
                    widthOverride='96px'
                >
                    Cancel
                </Button>
            </Grid>
            <Grid item xs={5}>
                <Button
                    disabled={!isDirty || !isValid}
                    type='submit'
                    variant='contained'
                    color='primary'
                    fontSize='14px'
                    heightOverride='28px'
                    widthOverride='200px'
                >
                    Send Verification Code
                </Button>
            </Grid>
        </>
    );

    const checkCodeForm = (
        <>
            <Grid item xs={12} style={{ marginLeft: '20px', marginBottom: '20px' }}>
                <Typography variant='h6'>{newEmail}</Typography>
            </Grid>
            <Grid
                item
                xs={12}
                style={{ marginLeft: '20px', marginRight: '20px', marginBottom: errorMessage ? '43px' : '64px' }}
            >
                <InputLabel id='verificationCodeLabel'>Verification code</InputLabel>
                <Controller
                    as={TextField}
                    control={control}
                    variant='outlined'
                    defaultValue=''
                    name='verificationCode'
                    rules={{
                        required: true,
                        validate: validateVerificationCode,
                        maxLength: 6,
                    }}
                    id='verificationCode'
                    fullWidth
                    error={!!errorMessage || errors.account?.type === 'required'}
                    helperText={errorMessage || 'Verification code required'}
                    placeholder='000000'
                    inputProps={{
                        maxLength: 6,
                    }}
                    InputLabelProps={{
                        shrink: true,
                        error: false,
                    }}
                />
            </Grid>
            <Grid item xs={6} style={{ marginLeft: '20px', marginRight: '28px' }}>
                <Button
                    type='button'
                    variant='contained'
                    color='secondary'
                    fontSize='14px'
                    onClick={closeModal}
                    heightOverride='28px'
                    widthOverride='96px'
                >
                    Cancel
                </Button>
            </Grid>
            <Grid item xs={3}>
                <Button
                    disabled={!isDirty || !isValid}
                    type='submit'
                    variant='contained'
                    color='primary'
                    fontSize='14px'
                    heightOverride='28px'
                    widthOverride='120px'
                >
                    Set Email
                </Button>
            </Grid>
        </>
    );

    const body = (
        <form onSubmit={handleSubmit(onSubmit)} style={modalStyle} className={classes.paper}>
            <Grid container item xs={12}>
                <Grid item xs={12} style={{ height: '44px', marginLeft: '20px', marginTop: '10px' }}>
                    <Typography variant='h3'>Update email</Typography>
                </Grid>
                <Grid item xs={12} style={{ height: '0px', border: '1px solid #D2D2D2' }} />
                <Grid container alignItems='center' item xs={8} style={{ height: '62px', marginLeft: '20px' }}>
                    <Typography variant='subtitle2'>This will update the email for the selected patient:</Typography>
                </Grid>
                {!isCodeSent ? sendCodeForm : checkCodeForm}
            </Grid>
        </form>
    );

    return (
        <Modal open={open} onClose={closeModal}>
            {body}
        </Modal>
    );
}

export default withLogger(UpdateEmailModal);
