import { Grid, IconButton, Typography } from '@material-ui/core';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import React from 'react';
import withLogger from './HOC/WithLogger';
type TemporaryPasswordComponentProps = {
    show: boolean;
    setShow: (arg: boolean) => void;
    message: string;
    successful: boolean;
};

function TemporaryPasswordComponent({ show, setShow, message, successful }: TemporaryPasswordComponentProps) {
    if (show && successful) {
        return (
            <>
                <Grid container direction='row' xs={12}>
                    <Grid item style={{ marginTop: 15 }}>
                        <Typography variant='h4' style={{ color: '#084897' }}>
                            {`Temporary password set:`}
                            <span className='searchMessage'>{message}</span>
                        </Typography>
                    </Grid>
                    <Grid item style={{ marginTop: 5, marginLeft: 5 }}>
                        <IconButton
                            onClick={() => {
                                setShow(false);
                            }}
                        >
                            <CancelOutlinedIcon color='primary'></CancelOutlinedIcon>
                        </IconButton>
                    </Grid>
                </Grid>
            </>
        );
    } else if (show && !successful) {
        return (
            <>
                <Grid container direction='row' xs={12}>
                    <Grid item className='tempPasswordErrorMessage'>
                        <Typography variant='h4' style={{ color: '#084897' }}>
                            {message}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton
                            onClick={() => {
                                setShow(false);
                            }}
                        >
                            <CancelOutlinedIcon color='primary'></CancelOutlinedIcon>
                        </IconButton>
                    </Grid>
                </Grid>
            </>
        );
    } else {
        return (
            <Grid item xs={12} className='searchOutputSpacer'>
                &nbsp;
            </Grid>
        );
    }
}

export default withLogger(TemporaryPasswordComponent);
